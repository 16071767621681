<template>
  <v-container class="payment-type payment-type-redirect">
    <i18n
      :path="`payment.paymentTypeList.tooltip.${paymentType.paymentTypeId}`"
      tag="span"
      class="tooltip-payment-methods"
    />
    <PrebilledAmount
      :mode="mode"
      :order-id="orderId"
      :paymentTypeId="paymentType.paymentTypeId"
    />
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end mt-5">
      <v-col cols="6" md="6" class="d-flex" v-if="mode != 'order'">
        <v-btn
          outlined
          color="primary"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          class="bg-white"
          >{{ $t("common.back") }}
        </v-btn>
      </v-col>
      <v-col cols="6" md="6" class="d-flex justify-end">
        <v-btn
          color="primary"
          class="ml-2 gateway-btn"
          v-on:click="buy"
          depressed
          :disabled="paymentDisabled"
        >
          {{ $t("payment.paymentTypeList.orderConfirm") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PrebilledAmount from "./PrebilledAmount";
import ResponseMessage from "@/components/ResponseMessage";
import GatewayUtilsService from "./gatewayUtilsService";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";
import { mapActions } from "vuex";
var vm;

export default {
  name: "PaymentTypeRedirect",
  mixins: [gateway],
  props: { paymentDisabled: { type: Boolean, default: false } },
  data() {
    return { loading: false, error: null, response: {} };
  },
  components: { PrebilledAmount, ResponseMessage },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart"
    }),
    async buy() {
      // UPDATE CART INFO AND PAY
      var _this = this;
      vm.loading = true;
      try {
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: _this.paymentType.paymentTypeId
          });
          if (cart) {
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId
          );
          if (response) {
            vm.payResponseOk(response);
          } else {
            this.$router.push("/payment-error");
          }
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    payResponseOk(cart) {
      let orderId = null;
      if (cart && cart.paymentUrl) {
        global.EventBus.$emit("pay-response-ok", cart);
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "get", orderId);
      } else {
        this.loading = false;
      }
    },
    payResponseKo(data) {
      this.loading = false;

      GatewayUtilsService.handlePayResponseKo(data, this.orderId);
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      GatewayUtilsService.payment(
        paymentUrl,
        paymentParameters,
        method,
        orderId
      );
    },
    goBack() {
      this.$router.push("/checkout");
    }
  },
  created() {
    vm = this;
  }
};
</script>
